<template>
  <div id="resources-list">
    <div>
      <div class="container space-2">
        <div class="w-md-85 w-lg-65 text-center mx-md-auto mb-5">
          <h2>API Provider Crash Course</h2>
        </div>
        <div class="row">
          <div v-for="(item, index) in List" :key="index" class="col-sm-6 col-lg-4 mb-7 mb-sm-5">
            <!-- Card -->
            <a class="card h-100" :href="`${item.url}`" target="_blank">
              <img class="card-img-top" :src="require('@/assets/img/resource-library/crash-course/' + `${ $t(item.image) }` + '.jpg')">
              <div class="card-body">
                <h4 class="mb-0">{{ $t(item.title) }}</h4>
                <p class="text-primary mt-6 font-weight-bold">Read More <i class="far fa-arrow-right ml-1" /></p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import 'bootstrap'
import Resources from './list.json'

export default {
  name: 'ResourcesList',
  data() {
    return {
      List: Resources.Resources
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-header {
  border-bottom: 0;
}
</style>
