<template>
  <div>
    <Hero />
    <ResourcesList />
    <CTA />
  </div>
</template>

<script>
import Hero from './components/hero'
import ResourcesList from './components/resources-list'
import CTA from './components/cta'

export default {
  name: 'Resources',
  components: {
    Hero,
    ResourcesList,
    CTA
  },
  metaInfo() {
    return {
      title: 'FabriXAPI',
      titleTemplate: 'Resources | %s',
      link: [
        { rel: 'canonical', href: 'https://www.fabrixapi.com/resources' }
      ],
      meta: [
        { name: 'description', content: 'Discover valuable resources on API management and API portals. Learn more!' },
        { property: 'og:site_name', content: 'FabriXAPI' },
        { property: 'og:title', content: 'Resources | FabriXAPI' },
        { property: 'og:description', content: 'Discover valuable resources on API management and API portals. Learn more!' },
        { property: 'og:image', content: 'https://www.fabrixapi.com/assets/img/thumbnails/fabrixapi-thumbnail.jpg' },
        { property: 'og:url', content: 'https://www.fabrixapi.com/resources' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
